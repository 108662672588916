@import "bootstrap/bootstrap.less";
@import "fonts.less";
@import "variables.less";

html, body {
  width:100%;
  height:100%;
}

body {
  font-family:@standardFont;
  font-weight:300;
}

#logo {
  display:block;
  width:65%;
  margin:45px auto;
}

#content {
  p {
    font-size:1.2em;
  }

  p.error {
    color:@colorRed;
    font-weight: 200;
    font-size:1em;
  }
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}