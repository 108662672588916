@font-face {
  font-family: 'titillium';
  font-weight: 900;
  src: url('../fonts/titilliumweb-black-webfont.eot');
  src: url('../fonts/titilliumweb-black-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-black-webfont.woff2') format('woff2'),
  url('../fonts/titilliumweb-black-webfont.woff') format('woff'),
  url('../fonts/titilliumweb-black-webfont.ttf') format('truetype'),
  url('../fonts/titilliumweb-black-webfont.svg#titillium_webblack') format('svg');
}

@font-face {
  font-family: 'titillium';
  font-weight: 500;
  src: url('../fonts/titilliumweb-bold-webfont.eot');
  src: url('../fonts/titilliumweb-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-bold-webfont.woff2') format('woff2'),
  url('../fonts/titilliumweb-bold-webfont.woff') format('woff'),
  url('../fonts/titilliumweb-bold-webfont.ttf') format('truetype'),
  url('../fonts/titilliumweb-bold-webfont.svg#titillium_webbold') format('svg');
}

@font-face {
  font-family: 'titillium';
  font-weight: 200;
  src: url('../fonts/titilliumweb-light-webfont.eot');
  src: url('../fonts/titilliumweb-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-light-webfont.woff2') format('woff2'),
  url('../fonts/titilliumweb-light-webfont.woff') format('woff'),
  url('../fonts/titilliumweb-light-webfont.ttf') format('truetype'),
  url('../fonts/titilliumweb-light-webfont.svg#titillium_weblight') format('svg');
}

@font-face {
  font-family: 'titillium';
  font-weight: 300;
  src: url('../fonts/titilliumweb-regular-webfont.eot');
  src: url('../fonts/titilliumweb-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/titilliumweb-regular-webfont.woff2') format('woff2'),
  url('../fonts/titilliumweb-regular-webfont.woff') format('woff'),
  url('../fonts/titilliumweb-regular-webfont.ttf') format('truetype'),
  url('../fonts/titilliumweb-regular-webfont.svg#titillium_webregular') format('svg');
}